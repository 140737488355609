<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import Gen from '@helper/Gen';
	import swal from 'sweetalert';

	export default {
		name: "AppWebSettings",
		extends: Base,
		data() {
			return {
				Name: "AppWebSettings",
				cmp_name: {},
				pagination: {},
				cmp_phone: {},
				// cmp_phone_free_fee: {},
				// cmp_email: {},
				cmp_facebook: {},
				cmp_instagram: {},
				cmp_twitter: {},
				cmp_youtube: {},
				adm_email: {},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods: {
			showMediaSosial(id) {
				$("#editSosmed").modal()
				this.sosial_media_detail = {}
				BOGen.apirest("/" + this.Name, {
					id: id,
					type: 'showMediaSosial'
				}, (err, resp) => {
					if (err) console.log(err)
					this.sosial_media_detail = resp.data
				}, "POST")
			},
			submitForm(e, callback) {
				if (e && e.btnLoading()) return;
				let input = {}
				input.cmp_name = this.cmp_name
				input.cmp_phone = this.cmp_phone
				// input.cmp_phone_free_fee = this.cmp_phone_free_fee
				// input.cmp_email = this.cmp_email
				input.cmp_facebook = this.cmp_facebook
				input.cmp_instagram = this.cmp_instagram
				input.cmp_twitter = this.cmp_twitter
				input.cmp_youtube = this.cmp_youtube
				input.adm_email = this.adm_email
				input.pagination = this.pagination
				input.type = 'update'
				BOGen.apirest("/" + this.Name, input, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error == 1) return Gen.info(resp.message, resp.style, 3000, '.info')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 3000, '.info')
						this.refreshData()
					}
				}, "POST")
			},
			updateAccountSetting(e, callback) {
				if (e && e.btnLoading()) return;
				let input = {}
				input.marketing_wa = this.marketing_wa
				input.greeting_wa = this.greeting_wa
				input.username_instagram = this.username_instagram
				input.type = 'updateAccountSetting'
				BOGen.apirest("/" + this.Name, input, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error == 1) return Gen.info(resp.message, resp.style, 3000, '.info2')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 3000, '.info2')
						this.refreshData()
					}
				}, "POST")
			},
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			},
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row">
			<div class="col-12">
				<VForm @resp="submitForm" action="post">
					<div class="card">
						<div class="card-body">
							<div class="d-flex justify-content-between align-items-center">
								<h5 class="card-title">Social Account</h5>
							</div>
							<hr>
							<div class="row">
								<div class="col-12">
									<div class="info"></div>
									<div class="row">
										<div class="col-md-6">
											<div class="card border">
												<div class="card-body">
													<div class="row">
														<div class="col-md-9">
															<h5 class="card-title">{{cmp_facebook.aws_title}}</h5>
														</div>
														<div class="col-md-3">
															<Checkbox v-model="cmp_facebook.aws_is_active">Active</Checkbox>
														</div>
														<div class="col-md-12">
															<BoField v-model="cmp_facebook.aws_val"></BoField>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-6">
											<div class="card border">
												<div class="card-body">
													<div class="row">
														<div class="col-md-9">
															<h5 class="card-title">{{cmp_instagram.aws_title}}</h5>
														</div>
														<div class="col-md-3">
															<Checkbox v-model="cmp_instagram.aws_is_active">Active</Checkbox>
														</div>
														<div class="col-md-12">
															<BoField v-model="cmp_instagram.aws_val"></BoField>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-6">
											<div class="card border">
												<div class="card-body">
													<div class="row">
														<div class="col-md-9">
															<h5 class="card-title">{{cmp_twitter.aws_title}}</h5>
														</div>
														<div class="col-md-3">
															<Checkbox v-model="cmp_twitter.aws_is_active">Active</Checkbox>
														</div>
														<div class="col-md-12">
															<BoField v-model="cmp_twitter.aws_val"></BoField>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-6">
											<div class="card border">
												<div class="card-body">
													<div class="row">
														<div class="col-md-9">
															<h5 class="card-title">{{cmp_youtube.aws_title}}</h5>
														</div>
														<div class="col-md-3">
															<Checkbox v-model="cmp_youtube.aws_is_active">Active</Checkbox>
														</div>
														<div class="col-md-12">
															<BoField v-model="cmp_youtube.aws_val"></BoField>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-6">
											<div class="card border">
												<div class="card-body">
													<div class="row">
														<div class="col-md-9">
															<h5 class="card-title">Whatsapp</h5>
														</div>
														<div class="col-md-3">
														</div>
														<div class="col-md-12">
															<BoField keyFilter="phoneKey" v-model="cmp_phone.aws_val"></BoField>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-6">
											<div class="card border">
												<div class="card-body">
													<div class="row">
														<div class="col-md-9">
															<h5 class="card-title">Admin Email</h5>
														</div>
														<div class="col-md-3">
														</div>
														<div class="col-md-12">
															<BoField keyFilter="email" v-model="adm_email.aws_val"></BoField>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="d-flex justify-content-between align-items-center">
								<h5 class="card-title">Footer</h5>
							</div>
							<hr>
							<div class="row mb-5">
								<div class="col-md-6">
									<BoField :label="'Company Name'" v-model="cmp_name.aws_val">
									</BoField>
								</div>
							</div>
							<div class="d-flex justify-content-between align-items-center">
								<h5 class="card-title">Pagination</h5>
							</div>
							<hr>
							<div class="row mb-5">
								<div class="col-md-3">
									<BoField v-model="pagination.aws_val"></BoField>
								</div>
							</div>
							<div class="row">
								<div class="col-12 text-right">
									<button type="submit" class="btn btn-loading btn-success">Submit Changes</button>
								</div>
							</div>
						</div>
					</div>
				</VForm>
			</div>
		</div>
	</div>
</template>